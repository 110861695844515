import React from "react";
import DefaultLayout from "../layouts/DefaultLayout";
import DefaultHelmet from "../seo/DefaultHelmet";
import ShopifyThemesSlider from "../sections/ShopifyThemes/ShopifyThemesSlider";
import ShopifyThemesDescription from "../sections/ShopifyThemes/ShopifyThemesDescription";
import ShopifyThemesList from "../sections/ShopifyThemes/ShopifyThemesList";
import Pagination from "../components/Pagination";
import beaconWidgetIds from "../settings/beacon-widget-ids";
import useBeaconWidget from "../hooks/useBeaconWidget";
import PageHeader from "../components/PageHeader";

const ShopifyThemes = ({ pageContext }) => {
	const {
		themes,
		totalCount,
		themesPerPage,
		pagesCount,
		currentPage,
		originPath,
		themesDescription,
		themesSlides,
		pageHeader,
		metaData,
	} = pageContext;

	const pageTitle = metaData?.title || "Shopify themes";
	const pageDescription = metaData?.description || "Shopify themes description";

	const headerBelowBanner = pageHeader?.position === 'below' || pageHeader?.position === 'bellow';
	const titleBelowBannerMarkup = headerBelowBanner && pageHeader?.title
		? <PageHeader
			{...pageHeader}
			title={pageHeader?.title || pageTitle}
			isGeneral={true}
		/>
		: null;

	useBeaconWidget(beaconWidgetIds.THEME);

	return (
		<DefaultLayout>
			<DefaultHelmet title={pageTitle} description={pageDescription} />

			<ShopifyThemesSlider
				slides={themesSlides}
				pageHeader={pageHeader}
			/>

			{headerBelowBanner && titleBelowBannerMarkup}

			{themesDescription ? <ShopifyThemesDescription
				title={themesDescription.title}
				text={themesDescription.text}
			/> : null}

			<ShopifyThemesList
					themes={themes}
					totalCount={totalCount}
					themesPerPage={themesPerPage}
			/>

			<Pagination
					pagesCount={pagesCount}
					currentPage={currentPage}
					originalPath={originPath}
			/>
		</DefaultLayout>
	);
};

export default ShopifyThemes;
