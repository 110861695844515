import React from "react";
import PropTypes from "prop-types";
import ProjectWrapperOneRow from "../../components/ProjectsList/ProjectWrapperOneRow";
import ProjectWrapperTwoRows from "../../components/ProjectsList/ProjectWrapperTwoRows";

const ShopifyThemesList = ({ themes }) => {
	const themesArray = [...themes];
	const themesWrappers = [];

	while (themesArray.length) {
		themesWrappers.push(themesArray.splice(0, 3));
	}

	return (
		<section className="projects-list shopify-themes-list">
			<div className="container container--mobile-small projects-list__container shopify-themes-list__container">
				{
					themesWrappers.map((themeWrapper, index) => {
						if (index % 2 === 0) {
							return (
								<ProjectWrapperTwoRows
									key={index}
									projectsWrapper={themeWrapper}
									path="shopify-themes"
								/>
							);
						}

						return (
							<ProjectWrapperOneRow
								key={index}
								projectsWrapper={themeWrapper}
								path="shopify-themes"
							/>
						);
					})
				}
			</div>
		</section>
	);
};

ShopifyThemesList.propTypes = {
	themes: PropTypes.array.isRequired,
	themesPerPage: PropTypes.number.isRequired,
	totalCount: PropTypes.number.isRequired
};

export default ShopifyThemesList;
