import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {Swiper, SwiperSlide} from "swiper/react";
import BackgroundImage from "gatsby-background-image";
import {convertToBgImage} from "gbimage-bridge";
import SwiperCore, {A11y, Navigation} from "swiper";
import IconChevronLeft from "../../icons/IconChevronLeft";
import {Link} from "gatsby";
import createValidLink from "../../helpers/urlFixer";
import PageHeader from "../../components/PageHeader";

const ShopifyThemesSlider = ({slides, pageHeader}) => {
    SwiperCore.use([Navigation, A11y]);

    const sliderParams = {
        slidesPerView: 1,
        loop: true,
        allowTouchMove: false,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
        },
        a11y: {
            prevSlideMessage: 'Previous slide',
            nextSlideMessage: 'Next slide'
        }
    };

    const contentAlignmentModifiers = {
        left: "shopify-themes-slider__content-wrapper--left",
        right: "shopify-themes-slider__content-wrapper--right",
        center: "shopify-themes-slider__content-wrapper--center",
        full_width: "shopify-themes-slider__content-wrapper--fullwidth"
    };

    return (
        <div className="shopify-themes-slider">
            <Swiper
                {...sliderParams}
            >
                {slides.map(({slide}, index) => {
                    const gatsbyImageData = slide.image.localFile?.childImageSharp?.gatsbyImageData;

                    if (!gatsbyImageData) {
                        return null;
                    }

                    const backgroundImage = convertToBgImage(gatsbyImageData);

                    const content = slide.content;

                    const title = content?.title;
                    const text = content?.text;
                    const buttonText = content?.button?.buttonText;
                    const buttonLink = createValidLink(content?.button?.buttonLink?.url);

                    const contentAlignment = content?.contentAlignment;
                    const textColor = content?.contentTextColor;

                    const headerOnBanner = pageHeader?.position === 'on';
                    const bannerTitleProps = headerOnBanner && pageHeader?.title
                        ? pageHeader
                        : {};

                    const bannerTitleMarkup = (
                        <PageHeader
                            {...bannerTitleProps}
                            title={headerOnBanner && index === 0 ? pageHeader?.title || title : title}
                            implemented={true}
                            className={"section-title shopify-themes-slider__title"}
                            isGeneral={headerOnBanner}
                        />
                    );

                    return (
                        <SwiperSlide key={index}>
                            <BackgroundImage
                                Tag="div"
                                className="shopify-themes-slider__wrapper"
                                {...backgroundImage}
                            >
                                <div
                                    className={
                                        classNames("container container--medium shopify-themes-slider__content-wrapper", contentAlignmentModifiers[contentAlignment])
                                    }
                                >
                                    <div
                                        className="shopify-themes-slider__content"
                                        style={{
                                            "--text-color": textColor
                                        }}
                                    >
                                        {bannerTitleMarkup}

                                        {text &&
                                            <p className="section-subtitle shopify-themes-slider__subtitle">
                                                {text}
                                            </p>
                                        }

                                        {buttonText && buttonLink &&
                                            <Link to={buttonLink}
                                               className="button button--large button--transparent shopify-themes-slider__button">
                                                {buttonText}
                                            </Link>
                                        }
                                    </div>
                                </div>
                            </BackgroundImage>
                        </SwiperSlide>
                    );
                })}
                {
                    slides.length > 1 ? <button className="swiper-button-prev">
                        <IconChevronLeft/>
                    </button> : null
                }

                {
                    slides.length > 1 ? <button className="swiper-button-next">
                        <IconChevronLeft/>
                    </button> : null
                }

            </Swiper>
        </div>
    );
}

ShopifyThemesSlider.propTypes = {
    slides: PropTypes.array.isRequired
};

export default ShopifyThemesSlider;
