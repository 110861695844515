import React from "react";
import PropTypes from "prop-types";

const ShopifyThemesDescription = ({ title, text }) => {
	return (
		<section className="shopify-themes-description">
			<div className="container container--medium">
				{title &&
					<h2 className="medium-title shopify-themes-description__title">
						{title}
					</h2>
				}

				<p className="shopify-themes-description__text">
					{text}
				</p>
			</div>
		</section>
	);
};

ShopifyThemesDescription.propTypes = {
	title: PropTypes.string,
	text: PropTypes.string.isRequired
};

export default ShopifyThemesDescription;
